<template>

  <div class="page">
  <vHeader></vHeader>
  <vMain></vMain>
  <portfolio></portfolio>
  <scrollUp></scrollUp>


</div>
</template>

<script>
import vHeader from '@/components/vHeader.vue'
import vMain from '@/components/vMain.vue'
import portfolio from '@/components/portfolio.vue'
import scrollUp from '@/components/scrollUp.vue'

export default {
  components: {
    vHeader,
    vMain,
    portfolio,
    scrollUp,

  }
}



</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@300;400;700&display=swap');


$primecolor: #fff;
$seccolor: #2BDAD9;
$bgcolor: #091B25;

* {
  box-sizing: border-box;
  margin: 0;
  font-family: 'JetBrains Mono', monospace;
  font-size: 16px;
  font-weight: lighter;
  color: $primecolor;
  text-decoration: none;
  border-radius: 2px;
  cursor: url('@/img/cursor1.png'), auto;

}

.page {
  position: relative;
  overflow: hidden;
}


</style>
