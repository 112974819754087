<template>
    <div class="main" name="top">
        <img src="@/img/main-bg.png" alt="" class="bg">
        <div>
            <h1 class="about-title">EASEL<span>PIX</span> DIGITAL AGANCY</h1>
            <p class="text-text">какое-то мелкое описание, которое никому нахуй не нужно и никто не будет читать Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis nihil omnis eum inventore dolorum similique minima quos ipsum maiores molestias, autem officiis veniam corrupti hic voluptas? Fugiat architecto vitae aspernatur!</p>
            <button class="about-btn" @click="showM=true">ОСТАВИТЬ ЗАЯВКУ</button>
        </div>

        <footer>
            <div>
                <img src="../img/Line.png" alt="">
                <p>РАССРОЧКА</p>
            </div>
            <div>
                <img src="../img/Line.png" alt="">
                <p>10 ЛЕТ НА РЫНКЕ</p>
            
            </div>
            <div>
                <img src="../img/Line.png" alt="">
                <p>СОБСТВЕННЫЕ СЕРВЕРА</p>
            </div>    
        </footer>
    </div>
    <modal :item="showM" :fun="canselShowM"></modal>

</template>

<script>
import modal from '@/components/modal.vue'

export default {

  components: {
    modal
  },
  data() {
        return {
            showM: false
        }
    },
    methods: {
        canselShowM () {
            this.showM = false
        }
    }
}
</script>

<style lang="scss" scoped>

$primecolor: #fff;
$seccolor: #2BDAD9;
$bgcolor: #091B25;



.main {
    background: none;

    
    .bg {
        position: absolute;
        top: 0;
        width: 100%;
        // height: 100%;
        z-index: -3;
        max-height: 2160px;
    }



        div {
            width: 20vw;
            margin: 5vw 0 0 150px;
            z-index: 12;
            

            h1 {
                font-size: 4vw;
                font-weight: 200;
                z-index: 13;

                span {
                    font-size: 4vw;
                    font-weight: 200;
                    color: $seccolor;
                }
            }


            p {
                font-size: .8vw;
            }
            
            button {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 3vw;
            width: 11vw;
            height: 3vw;
            background: none;
            padding: 15px;
            border-radius: 6vw 0 6vw 0;
            border: 1px solid $seccolor;
            color: $seccolor;
            box-shadow: 0 0 1vw #6ddada ;
            text-shadow: 0 0 1vw #6ddada ;
            cursor: pointer;
            transition-duration: 0.3s;
            font-size: 0.8vw;

                &:hover {
                    box-shadow: 0 0 2vw #6ddada ;
                    text-shadow: 0 0 2vw #6ddada ;
                }
        }
        }
        


        footer {
            display: flex;
            flex-direction: row;
            margin: 4vw 5vw 0 150px;
    
            

                div {
                    display: flex;
                    margin: 0 6vw 4vw 0;
                    position: relative;
                    align-items: center;
                    width: 6vw;
                    z-index: 12;
                    img {
                        height: 3vw;
                        margin-right: 1vw;
                    }
                }

        }

    }






    

</style>