<template>
        <vHeader></vHeader>
</template>
<script>
import vHeader from '@/components/vHeader.vue'

export default {

  components: {
    vHeader
  }
}
</script>
<style></style>