<template>
    <div class="wr" v-if="item" @click.self="fun">
        <div class="modal" >
            <button @click.self="fun">✕</button>
            <h1>Заполните анкету, чтобы обсудить проект</h1>
            <h5>или свяжитесь с нами по телефону 
                <a href="tel:+7 999 999 99 99">+7 999 999 99 99</a></h5>
            <div class="form">
                <h1>Ваши контакты</h1>
                <input placeholder="Имя и Фамилия*">
                <input placeholder="E-mail*">
                <input placeholder="Телефон*">
                <input placeholder="Компания">
            </div>
            <div class="text">
                <h1>О Проекте</h1>
                <textarea type="text" placeholder="Краткое описание проекта*"></textarea>
                <p>* обязательно к заполнению</p>
                <button>
                    <img src="@/img/pencil.png" alt="">
                    Прикрепить файл
                </button>
            </div>
            <div class="footer">
                <h5>Отправляя форму, вы соглашаетесь с политикой обработки и использования персональных данных</h5>
                <button>ОТПРАВИТЬ</button>
            </div>
        </div>
    </div>
    </template>
    <script>
    export default {
        
        props: [
            'item', 'fun'
        ],
    }
    </script>

<style scoped lang="scss">
    
$primecolor: #fff;
$seccolor: #2BDAD9;
$bgcolor: #091B25;
    .wr {
    
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.371);
    z-index: 15;
    
    
        .modal {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: column;
            width: 50vw;
            // height: 40vw;
            border-radius: 30px;
            background-color: $primecolor;
            padding:2% 5%;
            

                button {
                    position: absolute;
                    right: 2vw;
                    top: 0vw;
                    width: 3vw;
                    height: 3vw;
                    font-size: 4vw;
                    background: none;
                    color: $seccolor;
                    border: none;
                    cursor: pointer;
                  
            
                }
        
                h1 {
                    position: relative;
                    color: $bgcolor;
                    font-size: 1.5vw;
                    margin-bottom: 0.8vw;
                    
                }

                h5 {
                    font-size: 0.8vw;
                    color: $bgcolor;

                    a {
                        text-decoration: none;
                        color: $bgcolor;
                        font-size: 0.8vw;
                        transition: linear 0.5s;
                        &:hover {
                            text-shadow: 0 0 15px $seccolor;
                        }
                        
                    }
                }
                .form {
                    position: relative;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    width: 100%;
                    margin-top: 2vw;
                    
                    h1 {
                        color: $bgcolor;
                        width: 100%;
                    }
                    

                    input {
                        
                        width: 40%;
                        height: 3vw;
                        margin-right: 1vw;
                        margin-bottom: 1vw;
                        font-size: 0.8vw;
                        border-bottom: 0.5vw solid $seccolor;
                        border-image: url('../img/inputLine.png') 0 0 30 0 stretch;
                        color: $bgcolor;
                        padding: 15px;
                        outline: none;

                        &:focus {
                            border: 10px solid #fff;
                            border-image: url('../img/inputLine.png') 0 0 30 0 stretch;
                            outline: none;

                            
                        }

                    }
                }
                
                .text {
                    margin-top: 1vw;


                    textarea {
                        width: 100%;
                        height: 8vw;
                        border-radius: 0 0 7vw 0;
                        border: 1px solid $seccolor;
                        padding: 0.5vw;
                        outline: none;
                        font-size: 0.8vw;
                        color: $bgcolor;


                        &:focus {
                            text-decoration: none;
                            border: 1px solid $seccolor;
                            outline: none;
                        }
                    }

                    p {
                        color: $seccolor;
                    }

                    button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        margin-top: 1vw;
                        margin-left: 0.8vw;
                        color: $bgcolor;
                        border: none;
                        font-size: 0.8vw;
                        width: 10vw;
                        vertical-align: center;
                        img {
                            width: 1.5vw;
                            margin-right: 0.8vw;
                            cursor: pointer;
                        }
                    }
                }

                .footer {
                    margin-top: 2vw;
                    display: flex;
                    justify-content: left;
                    align-items: center;
                
                    h5 {
                        width: 50%;
                        font-size: 0.6vw;
                        margin-right: 30%;
                    }

                    button {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 3vw;
                        width: 10vw;
                        background: none;
                        // padding: 15px;
                        border-radius: 6vw 0 6vw 0;
                        border: 1px solid $seccolor;
                        color: $seccolor;
                        transition: linear 1s;
                        font-size: 0.8vw;

                            &:hover {
                                border: none;
                                color: $primecolor;
                                background: $bgcolor;
                                transition: linear 1s;
                                
                            }
                                    
                    }
                }
            }

    }

    
    
    

    
    </style>