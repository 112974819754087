
import { createRouter, createWebHistory } from 'vue-router';
import main from '@/components/vMain.vue';
import portfolio from '@/components/portfolio.vue';
import news from '@/components/news.vue';
import contacts from '@/components/contacts.vue';
import products from '@/components/products.vue';


export default createRouter({
    history: createWebHistory(),
    routes: [
        {path: '/', component: main},
        {path: '/portfolio', component: portfolio},
        {path: '/contacts', component: contacts},
        {path: '/news', component: news},
        {path: '/products', component: products},
    ]
}) 