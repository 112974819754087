<template>

  <div class="portfolio">
      <img src="@/img/lines.png" alt="" class="lines">
      <h1>Портфолио</h1>
      <h5>Описание для клиентов Lorem ipsum dolor, sit amet consectetur adipisicing elit. Praesentium placeat molestias nobis molestiae ducimus!</h5>
      <div class="ell"></div>
      <div class="ell bottom"></div>
      <vCarousel
        :carousel_data="sliderItems">
      </vCarousel>

      <img src="@/img/stars.png" alt="" class="stars">

      </div>
</template>
<script>
import vCarousel from '@/components/v-carousel.vue'

export default {

components: {
    vCarousel,
    
},

  data() {
      return {

          sliderItems: [
              {id: 1, name: 'img1', img: '1.jpg'},
              {id: 2, name: 'img2', img: '2.jpg'},
              {id: 3, name: 'img3', img: '3.jpg'},
              {id: 4, name: 'img4', img: '1.jpg'},
              {id: 5, name: 'img5', img: '2.jpg'}
          ]

          
      }
  }
}
</script>

<style lang="scss" scoped>

$primecolor: #fff;
$seccolor: #2BDAD9;
$bgcolor: #091B25;


.portfolio {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 2160px;
  overflow: hidden;
  background-color: $bgcolor;
    .lines {
      width: 100%;
  

    }

    .ell {
      position: absolute;
      right: -3vw;
      top: 20%;
      width: 324px;
      height: 324px;
      flex-shrink: 0;
      border-radius: 324px;
      background: rgba(43, 218, 217, 0.40);
      filter: blur(150px);
      &.bottom {
        left: 10%;
        bottom: 40%;
        
      }
    }

    .stars {
      position: absolute;
      width: 100vw;
      max-width: 2160px;
      max-height: 1980px;
      top: 0;
      left: 0;
    }

    h1 {
        margin: 4vw 0 2vw 4vw;
        font-size: 2vw;
      }

      h5 {
        margin: 0 0 2vw 4vw;
        font-size: 1vw;
      }
}

</style>