<template>
    <header>
        <router-link to="/main" class="logo">
            <img src="@/img/main-logo.png" alt="">
        </router-link>
        <div>
            <router-link to="/">О нас</router-link>
            <router-link to="/portfolio">Портфолио</router-link>
            <router-link to="/products">Продукты</router-link>
            <router-link to="/news">Новости</router-link>
            <router-link to="/contacts">Контакты</router-link>
        </div>
    </header>
</template>

<script>

</script>

<style lang="scss" scoped>

$primecolor: #fff;
$seccolor: #2BDAD9;
$bgcolor: #091B25;

header {
    

    display: flex;

    align-items: center;
    margin: 0 150px;
    padding-top: 2vw;

    .logo {
        margin-right: 10%;

    }
    img {
        width: 10vw;
        height: 4vw;
        
    }
        div {
            display: flex;
            align-items: center;
            
            
            a {
                margin-left: 2vw;
                border-bottom: 2px solid rgba(255, 0, 0, 0);
                transition-duration: 0.3s;
                font-size: 0.8vw;
                    &:hover {
                        border-bottom: 2px solid $seccolor;
                        text-shadow: 0 0 10px #6ddada ;
                    }
                    &.router-link-active {
                        color: $seccolor;
                        &:hover {
                            border-bottom: 2px solid $primecolor;
                        }
                    }
                }
        }
}



</style>