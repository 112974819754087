<template>
<div class="wrapper">
    <div class="v-carousel"
    :style="{ 'margin-left': '-' + (100 * currentSlideIndex) + '%'}"
    >
    <vCarouselItem
    v-for="item in carousel_data"
    :key="item.id"
    :item_data="item"
    >

    </vCarouselItem>

    </div>
    <button @click="prevSlide" class="btn--next">
        <svg class="arrow-prev" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71 71" fill="none">
            <path d="M35.5002 62.125C50.2048 62.125 62.1252 50.2046 62.1252 35.5C62.1252 20.7954 50.2048 8.875 35.5002 8.875C20.7957 8.875 8.87524 20.7954 8.87524 35.5C8.87524 50.2046 20.7957 62.125 35.5002 62.125Z" stroke="#2BDAD9" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M32.5419 44.375L41.4169 35.5L32.5419 26.625" stroke="#2BDAD9" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </button>
    <button @click="nextSlide" class="btn--prev" >
        <svg class="arrow-next" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71 71" fill="none">
            <path d="M35.5002 62.125C50.2048 62.125 62.1252 50.2046 62.1252 35.5C62.1252 20.7954 50.2048 8.875 35.5002 8.875C20.7957 8.875 8.87524 20.7954 8.87524 35.5C8.87524 50.2046 20.7957 62.125 35.5002 62.125Z" stroke="#2BDAD9" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M32.5419 44.375L41.4169 35.5L32.5419 26.625" stroke="#2BDAD9" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </button>
</div>
</template>

<script>
import vCarouselItem from '@/components/v-carousel-item.vue';

export default {
    name: "v-carousel",
    components: {
        vCarouselItem
    },
    props: {
        carousel_data: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            currentSlideIndex: 0
        }
    },

    methods: {
        prevSlide() {
            if (this.currentSlideIndex > 0) {
            this.currentSlideIndex--
        }
        },

        nextSlide() {
            if (this.currentSlideIndex >= this.carousel_data.length - 2) {
                this.currentSlideIndex = 0
            }
            else {
            this.currentSlideIndex++
        }
        }
    },

    computed: {}
}
</script>

<style lang="scss" scoped>

$primecolor: #fff;
$seccolor: #2BDAD9;
$bgcolor: #091B25;
.wrapper {
    position: relative;
    max-width: 100vw;
    overflow: hidden;
    margin: 6vw 4vw;
    background: none;
    z-index: 1;
    
   
}
.btn--prev {

    position: absolute;
    left: 87vw;
    top: 40%;
    width: 4vw;
    height: 4vw;
    background: none;
    border: none;

    svg {
        cursor: pointer;
        width: 3vw;
        height: 3vw; 
    }

    
}


.btn--next {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 40%;
    left: 0.5vw;
    background: none;
    border: none;


    svg {
        
        cursor: pointer;
        transform: rotate(180deg);
        width: 3vw;
        height: 3vw; 
    }
}

.v-carousel {
    display: flex;
    transition: all ease 1s;
}


</style>