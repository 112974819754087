<template>
    <div class="btn">
        <button class="scroll">
            <a href="#top">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71 71" fill="none">
                <path d="M35.5 62.125C50.2046 62.125 62.125 50.2046 62.125 35.5C62.125 20.7954 50.2046 8.875 35.5 8.875C20.7954 8.875 8.875 20.7954 8.875 35.5C8.875 50.2046 20.7954 62.125 35.5 62.125Z" stroke="#2BDAD9" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M32.5417 44.375L41.4167 35.5L32.5417 26.625" stroke="#2BDAD9" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a>
        </button>
    </div>
</template>

<script>

</script>

<style lang="scss" scoped>
$primecolor: #fff;
$seccolor: #2BDAD9;
$bgcolor: #091B25;

.btn {
    background: none;
    position: fixed;
    top: 90vh;
    right: 2vw;
    z-index: 12;


    button {
        border: none;
        background: none;

    }

    a{


        svg {
            width: 3vw;
            height: 3vw;
            transform: rotate(-90deg);
            cursor: url('@/img/cursor3.png'), pointer;

        }
    }


    
}

</style>