<template>
    <div class="slider-item">
      <img :src=" require('../img/sliderIMG/' + item_data.img)" alt="">
    </div>
</template>
<script>
export default {
    name: "slider-item",
    props: {
        item_data: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {}
    },
    computed: {}
}
</script>

<style scoped>

img {
    height: 20vw;
    width: 30vw;
    margin-right: 3vw;
        
  
}



@media (max-width: 768px) {
    img {
        width: 160px;
        height: 160px;
    }
}


</style>